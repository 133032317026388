.Login {
  display: flex;
  width: 100%;
  height: 100vh;
  color: black;
  background-color: #e3e5e7;

  .main {
    display: flex;
    width: 100%;
    height: 100vh;

    .login-area {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100vh;

      @media (min-width: 900px) {
        flex-direction: row;
      }
    }
  }

  .login__secondary--wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 50vh;
    justify-content: space-between;
    background-color: black;
    color: white;

    @media (min-width: 900px) {
      height: 100vh;
      width: 65%;
      align-items: center;
      padding: 0;
    }
  }

  .login__secondary {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 50vh;
    padding: 0 7%;
    justify-content: center;
    background-size: 600% 600%;
    animation: gradient-moving 12s ease infinite;
    background-color: black;
    color: white;

    @media (min-width: 900px) {
      height: 100vh;
      width: 65%;
      align-items: space-between;
      padding: 0;
    }


    h1.logo-deco {
      font-size: 20vw;
      margin: 0;  
      font-family: var(--font-fam-deco);
      line-height: 1;

      @media (min-width: 600px) {
        font-size: 12vw;
      }
    }

    .tagline {
      margin: 2rem 0 0 0;
      text-align: center;

      h2 {
        margin: 0;
        font-size: 1.4rem;
      }
    }

  }

  .login__primary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50vh;
    min-width: 320px;
    padding: 0 7%;
    background-color: white;

    @media (min-width: 900px) {
      height: 100vh;
      width: 35%;
      min-width: 180px;
      padding: 0;
    }

    .form {
      display: flex;
      width: 80%;
      flex-direction: column;
      justify-content: center;

      h3 {
        font-size: 1.25rem;
        line-height: 1.1;

        @media (min-width: 900px) {
          font-size: 1.5rem;
        }
      }

      .user-input {
        display: inline-block;
        width: 90%;
        background-color: transparent;
        border: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.25);
        padding: 1rem 1rem 0.25rem;
        margin: 0.5rem 0 1.5rem;
        outline-width: 1px;

        &::placeholder {
          font-size: 0.875rem;
        }
      }

      .hint {
        font-size: 0.75rem;
        line-height: 1.618;
        margin: 0 0 0.5rem 0;
        font-weight: 400;
      }

      .eye {
        position: relative;
        left: -2rem;
        font-size: 1.5rem;
        color: gray;
        cursor: pointer;
      }

      .wrapper {
        display: flex;

        .login__button {
          padding: 1.25em 2rem;
          margin: 1.5rem 0;
          font-size: 15px;
          font-weight: 500;
          cursor: pointer;
          color: white;
          background-color: black;
          border: 1px solid black;
          border-radius: 2px;

          &:hover {
            background-color: white;
            color: black;
          }
        }
      }
    }


    .login__options {
      display: flex;
      align-items: center;

      svg {
        display: inline-block;
        width: 4rem;
        height: 4rem;
        margin-right: 1rem;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  h3 {
    color: black;  
    font-size: 1.25rem;
    font-weight: 400;
    text-transform: uppercase;

    @media (min-width: 900px) {
      font-size: 1.75rem;
    }

    span {
      margin: 0 0.75rem 0 0;
      display: inline-block;
    }
  
    .active-link {
      padding: .5rem 1rem; 
      text-transform: uppercase;
      font-size: 1.25rem;
      color: white;
      background-color: black;

      &:hover {
        color: white;
        cursor: pointer;
      }
    }
  }
}
