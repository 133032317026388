.Avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;

  img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}
