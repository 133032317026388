.Front {
  
  .prima {
    display: flex;
    width: 100%;
    min-height: 30vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background-color: black;
    color: white;

    .chain__cell__add-word {
      position: relative;
      display: flex;
      justify-content: center;
      width: 220px;
      color: white;

      @media (min-width: 900px) {
        width: 64px;
        min-width: 64px;
        padding: 0 1.5rem;
      }

      &.extended {
        width: max-content;
        top: -28px;
        height: 58px;
      }
    }

    .ChainView {

      @media (min-width: 900px) {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1 1 100%;
        max-width: 900px;
      }
    }

    .chain {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media (min-width: 900px) {
        flex-direction: row;
        height: 100%;
        flex: 1 1 100%;
      }

      &__cell {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: center;

        @media (min-width: 900px) {
          flex-direction: row;
          justify-content: center;
        }

        &__show-word {
          position: relative;
          display: block;
          text-align: center;
          width: 180px;
          padding: 20px 0;
          font-size: 1.25rem;
          font-weight: 400;
          color: white;
          background-color: black;
          text-transform: uppercase;
          transition: all 0.3s ease-in-out;
          z-index: 20;

          @media (min-width: 900px) {
            width: auto;
          }

          .icon-button.delete {
            display: none;
          }

          &:hover {
            .icon-button.delete {
              position: absolute;
              top: 50%;
              right: 50%;
              width: 80px;
              height: 5px;
              transform: translate(50%, -50%);
              display: inline-flex;
              z-index: 32;
              font-size: 1.5rem;
              // border: 1px solid rgb(226, 226, 226);
              color: transparent;
              background-color: crimson;
            }
          }
        }

        &__add-word {
          position: relative;
          padding: 8px;
          z-index: 22;
        }
      }
    }

    .input-word {
      position: relative;
      display: inline-flex;
      width: 0;
      height: 0;
      border: 0;
      padding: 0;
      overflow: hidden;
      outline: none;
      transition: all 0.3s ease-in-out;
      z-index: 24;
    }

    .extended .input-word {
      position: relative;
      top: 24px;
      display: inline-flex;
      justify-content: center;
      width: 210px;
      height: 58px;
      line-height: 58px;
      border: 1px solid white;
      font-weight: 400;
      text-transform: uppercase;
      color: white;
      background-color: black;
      text-align: center;
      z-index: 26;
    }

    .icon-button.enter {
      display: none;
    }

    .icon-button:hover {
      cursor: pointer;
    }

    .extended .icon-button.enter {
      display: inline-flex;
      position: absolute;
      top: 48px;
      right: 1rem;
      z-index: 34;
      color: #ccc;

      &:hover {
        cursor: pointer;
        color: deepskyblue;
      }
    }

    p {
      text-align: center;
    }

    .button__wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3rem;
      position: relative;
      margin: 1rem;

      .done {
        display: inline-flex;
        font-size: 4rem;
        color: black;
        transition: all 0.3s ease-in-out;
        margin-left: 0.5rem;

        &:hover {
          cursor: pointer;
          color: deepskyblue;
        }
      }
    }

    @media (min-width: 900px) {
      min-height: 30vh;
      // justify-content: space-between;
    }

    h2 {
      font-size: 1.75rem;
      text-align: center;

      @media (min-width: 900px) {
        font-size: 2.25rem;
        margin-top: -2rem;
      }

      .digits {
        font-size: 3rem;
        font-weight: 500;
        color: #ffda08;
        padding: 0 0.125rem;
      }
    }


  }

  .terzia  {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: black;
    padding-bottom: 1.5rem;

    .box {
      max-width: 1200px;
      margin: 0 auto;

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
  }

  .secunda {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 30vh;
    color: black;

    .chainset-review {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 1rem;
      margin: 2rem auto;
      justify-content: center;
      align-items: center;

      @media (min-width: 900px) {
        max-width: 900px;
      }

      h2 {
        font-size: 2rem;
        margin-bottom: 2rem;
      }

      .chainset {
        width: 100%;
      }

      .game-button {
        margin: 2rem 0;

        @media (min-width: 900px) {
          min-width: 185px;
        }
      }

      .chain__wrapper {
        display: flex;
        line-height: 2;
        flex-wrap: wrap;
        width: 100%;
        border-bottom: 1px dotted #222;
        padding: 0 0.5rem 0.5rem 0;
        align-items: center;
        margin: 0 0 1rem;

        &:hover {
          cursor: pointer;
        }
        .chain__explanation {
          width: 100%;
          font-size: 0.875rem;
          line-height: 1.25;
          margin: 0.5rem 0;
        }

        .chain__header {
          width: 10%;
        }
        .chain {
          width: 80%;
        }
        .chain__score {
          width: 10%;
        }
      }
    }

    .ud-buttons {
      position: relative;
      top: 10px;
      display: flex;
      width: 2.5rem;
      height: 2.5rem;
      position: relative;
      margin-left: 0.5rem;

      .icon-button {
        margin-right: 0.25rem;

        &:last-of-type {
          margin-right: 0rem;
        }
      }
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 1rem;
      margin: 0 auto;

      @media (min-width: 900px) {
        max-width: 900px;
      }
    }
  }

  .front-rules {
    width: 100%;

    @media (min-width: 900px) {
      max-width: 600px;
    }

    h2 {
      font-size: 2.125rem;
      font-weight: 500;
      margin: 0 0 1rem;
      line-height: 1.4;
      letter-spacing: -0.5px;
    }

    h3 {
      font-size: 1.5rem;
      font-weight: 500;
      margin: 0.5rem 0;
      line-height: 1.4;
    }

    p,
    ul,
    ol {
      margin: 0 0 0.5rem 0;
      line-height: 1.618;
    }
  }

  .goal-short {
    display: inline-block;
    padding: 0 0 0 0.5rem;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.33;
    color: white;

    @media (min-width: 900px) {
      font-size: 0.875rem;
      margin: .5rem auto;
    }
  }
  .goal {
    display: flex;
    flex-direction: row;
    padding: 1.5rem 0 2.5rem 0;
    flex-direction: row;
    font-size: 1rem;
    font-weight: 500;
    justify-content: center;
    line-height: 2;

    @media (min-width: 900px) {
      font-size: 1.125rem;
      margin: 0 auto;
    }
  }

  .intro {
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .center {
    align-items: center;
  }

  .wrapper.explanations {
    display: flex;
    margin-top: 2rem;
    padding: 1rem;
    background-color: rgba(255, 191, 0, 0.138);

    @media (min-width: 900px) {
      max-width: 900px;
    }

    h3 {
      font-size: 1.25rem;
      font-weight: 500;
      margin: 0 0 1rem;
      line-height: 1.4;
    }
  }

  .ud-buttons {
    display: none;
  }

  .game {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 1rem;
    width: 100%;

    @media (min-width: 900px) {
      flex-direction: row;
      justify-content: space-between;
      max-width: 900px;
    }

    h1 {
      display: block;
      font-size: 2rem;
      font-weight: 400;
      text-align: left;
      margin: 0 0 1rem;

      @media (min-width: 900px) {
        font-size: 3rem;
      }
    }

    &__wrapper {
      display: flex;
      width: 100%;
      flex-direction: column;
      margin: 0 0 0 0;
      flex: 0 1 100%;

      @media (min-width: 900px) {
        margin: 1rem 0 0 0;
        width: 80%;
        flex: 0 1 80%;
      }
    }

    &__intro {
      margin: 0 0 0 0;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.6;

      @media (min-width: 900px) {
        margin: 0 3rem 0 0;
        font-size: 1.125rem;
        font-weight: 500;
      }
    }

    &__buttons {
      margin-top: 1rem;
      display: flex;
      flex: 0 1 auto;

      @media (min-width: 900px) {
        margin-top: 2rem;
        width: 20%;
        flex-direction: column;
        justify-content: center;
      }
    }

    .game-button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;
      min-width: 120px;

      @media (min-width: 900px) {
        min-width: 185px;
      }
    }

    .game-type-select {
      position: relative;
      display: inline-block;
      padding: 20px;
      justify-content: center;
      align-items: center;
      width: max-content;
      transform-origin: center;
      transition: transform 0.3s, background-color 0.3s;
      font-size: 0.875rem;
      font-weight: 400;
      color: white;
      background-color: rgb(34, 34, 34);
      max-width: 120px;
      border: 0;

      @media (min-width: 900px) {
        font-size: 1rem;
        min-width: 185px;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .game-button {

    &.play {
      padding: 20px;
      border: 0;
      color: black;
      background-color: white;

      &:hover {
        cursor: pointer;

        select {
          background-color: transparent;

          option {
            display: block;
            color: black;
          }
        }
      }

      select {
        display: inline-block;
        background-color: transparent;
        margin-right: 1rem;
        border: none;

        &:hover {
          cursor: pointer;

          option {
            display: block;
            color: black;
          }
        }

        option {
          display: none;
        }
      }
    }

    &.send {
      aspect-ratio: 1/1;
      padding: 2rem;
      border-radius: 50%;
      border: 1px solid white;
      color: black;

      font-size: 1.75rem;
      background-color: black;
    }
  }

  .game-misc {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
    background-color: rgba(255, 255, 255, 0.044);
  }

  .user-panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: fit-content;

    @media (min-width: 900px) {
      flex-direction: row;
      max-width: fit-content;
      align-items: center;
    }
  }

}

.icon-button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.125rem;
  color: #555;
  transition: all 0.3s ease-in-out;

  &.add,
  &.close {
    position: absolute;
    text-align: center;
    width: 2rem;
    height: 2rem;
    background-color: white;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    color: black;
    z-index: 28;

    &:hover {
      cursor: pointer;
      color: deepskyblue;
    }
  }

  &:hover {
    cursor: pointer;
  }

  &.delete {
    &:hover {
      cursor: pointer;
    }
  }

  &.edit {
    font-size: 1.25rem;

    &:hover {
      cursor: pointer;
      color: deepskyblue;
    }
  }

  &.enter {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 20;

    &:hover {
      cursor: pointer;
      color: limegreen;
    }
  }

}

.step-1.chain-len-2 .icon-button.add {
  background-image: linear-gradient(
    309deg,
    #ffda08,
    #33acff,
    #d2199a,
    #ff2828,
    #ffda08
  );
  font-size: 3rem;
  width: 3rem;
  height: 3rem;
  aspect-ratio: 1/1;
  border-radius: 50%;
  line-height: 3rem;
}
