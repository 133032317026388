.MyChains {
  position: relative;
  background-color: white;
  padding: 1rem;

  &__inner {
    columns: 1;
    position: relative;

    @media (min-width: 900px) {
      columns: 2;
    }
    @media (min-width: 1200px) {
      columns: 3;
    }
    @media (min-width: 1600px) {
      columns: 4;
    }
    @media (min-width: 1900px) {
      columns: 5;
    }

    &.fullwidth {
      columns: 1;
      padding: 0;
      width: calc(100% - 2rem);
    }
  }

  .chainset {
    width: 100%;
    font-size: 0.875rem;
    line-height: 2;
  }

  .chain__header {
    padding: 0 0 0 1rem;
    width: 3rem;
  }

  .fulwidth .chain-wrapper {
    width: 100%;
  }

  .chain__wrapper {
    display: flex;
    flex-wrap: wrap;
    line-height: 2;
    width: 100%;
    border-bottom: 1px solid #999;
    align-items: center;
    margin: 0 0 1rem;

    &:hover {
      cursor: pointer;
    }

    .chain {
      min-height: 32px;
    }
  }

  .modal {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    width: 600px;
    background-color: white;

    .icon-button.modal-close {
      position: absolute;
      display: inline-flex;
      top: 2.5rem;
      right: 0;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;
      padding: 0.5rem;
      color: white;
      background-color: black;
      border-radius: 50%;
      
      &:hover {
        cursor: pointer;
      }
    }
  } 

  .explanation {
    position: absolute;
    width: calc(100% - 4rem);
    top: 2rem;
    left: 2rem;
    font-size: 1rem;
    margin: 0.5rem 0;

  }

}
