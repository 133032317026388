.static {
  h2 {
    font-size: 2rem;
    line-height: 1.1;
    margin: 1rem 0;
  }

  h3 {
    font-size: 1.75rem;
    line-height: 1.1;
    margin: 1rem 0;
  }

  p, ul, ol {
    font-size: 1rem;
    line-height: 1.6;
    margin: 0 0 1rem 0;
  }
}