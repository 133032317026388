.Logo {

  .logo__short {
    display: flex;
    width: 50px;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    color: white;

    @media (min-width: 900px) {
      display: none;
    }

    &:hover {
      cursor: pointer;
    }
  }
  
  .logo__full {
    display: none;
    align-items: center;
    justify-content: center;
    width: max-content;
    color: white;

    h1  {
      font-size: 1.5rem;
      font-weight: 500;
      margin: 0;

      &:hover {
        color: #999;
        cursor: pointer;
      }
    }

    @media (min-width: 900px) {
      display: flex;
    }

    &:hover {
      cursor: pointer;
    }
  }

}

