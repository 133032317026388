.Dashboard {
  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: center;
  height: 100%;
  width: 100%;

  .header {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    background-color: black;
    color: white;
  }

  .avatar-edit {
    position: relative;
    display: flex;
    width: 120px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem 0 0;
  }

  .buttons {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: .75rem;
    
    .icon-button {
      margin: 0 0.25rem;
      color: black;
      background-color: white;
      border: 1px solid white;
      border-radius: 50%;
      padding: 0.25rem;

      font-size: 1.5rem;

      &:hover {
        color: white;
        background-color: black;
        }
    }
  }
  
  .add {
    visibility: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    background-color: white;
    
    &:hover {
      cursor: pointer;
      color: white;
      background-color: black;
    }
  }  

  .avatar-select {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background-color: black;

    .pic {
      border: 5px solid black;
      
      &:hover {
        cursor: pointer;
        border: 5px solid white;
      }

      img {
        display: block;
        height: 60px;
        width: 60px;
      }
    }
  }

  .atts {
    display: block;
    width: 140px;
    margin-left: 2rem;
    font-size: 1rem;
    color: white;

    .att {
      display: block;
      width: 100%;

      h2 {
        display: inline-flex;
        width: 100%;

        input {
          display: inline-block;
          width: 120px;
          font-size: 1.5rem;
          color: white;
          background-color: black;
          border: none;
          border-bottom: 1px solid white;
          padding: 0.25rem;
          margin: 0.25rem 0;
        } 
      }

      &.user-id {
        font-size: 0.875rem;
        color: #ccc;
        border-top: 1px dotted #ccc;
        padding-top: 0.75rem;
        margin-top: 0.75rem;
      }
      .icon-button {
        color: #ccc;
        margin: 0.5rem 0 0 0.5rem;
      }
    }
  }
}

.page.Dashboard__outer {
  background-color: white;
}