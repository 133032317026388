.Bar {
  position: relative;
  width: 100%;
  z-index: 30;

  .bar__inner {
    display: flex;
    width: 100%;
    padding: 1rem;
    background-color: #000000;
    color: #fff;
    border-bottom: 1px solid #555;
    justify-content: space-between;

    .bar__left {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      margin-left: 1rem;
    }

    .bar__center {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: calc(100% - 100px);
    }

    .bar__right {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 50px;
    }
  }

  select {
    display: inline-block;
    width: auto;
    height: 2rem;
    padding: 0 0.5rem;
    margin-right: 1rem;
    border: 1px solid #fff;
    border-radius: 3px;
    background-color: #000;
    color: #fff;
    font-size: 0.875rem;
    font-weight: 400;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;

    &:hover {
      cursor: pointer;
      background-color: #fff;
      color: #000;
    }
  }

  .menu-outer {
    position: relative;
    z-index: 15;
    width: auto;

    .material-symbols-outlined {
      display: block;
      font-size: 2rem;

      &.burger {

        &:hover {
          cursor: pointer;
        }
      }
    }

  }

  .menu-inner {
    position: fixed;
    top: 3.5rem;
    right: 1.5rem;

    min-width: 320px;
    max-width: 600px;

    background-color: #000;
    z-index: 20;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.77);
  }

  .menu {
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid white;

    &:hover {
      cursor: pointer;
    }


    .menu-item {
      display: flex;
      padding: 1rem;
      line-height: 1.8;
      font-size: 1rem;
      font-weight: 400;
      border-bottom: 1px solid white;

      a {
        color: #fff;
        text-decoration: none;
        display: inline-block;
        width: 100%;
        height: 100%;
      }


      &:last-of-type {
        border-bottom: 0;
      }

      &:hover {
        cursor: pointer;
      }

      &:last-of-type {
        margin-right: 0;
      }

    }
  }


  .lang {
    position: relative;
    z-index: 20;
  }

}