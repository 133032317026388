a {
  text-decoration: none;
  color: deepskyblue;

  &.black {
    color: black;
    text-decoration: underline;
  }
}

.black {
  color: black;
}

.page {
  min-height: calc(100vh - 2rem);
  background-color: #e2e4e6;
}

.main {
  position: relative;
  width: 100%;
}

h1 {
  font-size: 2rem;
  font-weight: 400;
  text-align: center;
  margin: 1rem 0 0.5rem 0;
}

h2 {
  font-size: 1.5rem;
  font-weight: 400;
  margin: 1.5rem 0 1rem;

  &:first-child {
    margin-top: 1rem;
  }
}

h3 {
  font-size: 1.25rem;
  font-weight: 400;
  margin: 1rem 0 0.5rem;
}



input::placeholder {
  color: #ccc;
  font-size: 0.75rem;
  opacity: 1;
}

.round {
  border-radius: 50%;
  aspect-ratio: 1/1;
}

.btn-small-hollow {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  color: #ccc;
  background-color: black;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 4px 8px;
  transition: all 0.3s ease-in-out;

  &:hover {
    cursor: pointer;
    color: white;
    border-color: white;
  }
}

.static {
  position: relative;
  width: 100%;
  max-width: 899px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.banner {
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  min-height: 240px;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.game-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  min-width: 180px;
  transform-origin: center;
  transition: transform 0.3s, background-color 0.3s;
  font-family: "Playfair Display", serif;
  font-size: 1.5rem;
  font-weight: 400;

  &:hover {
    cursor: pointer;
  }

  &.play {
    padding: 20px;
    border: 0;
    color: black;
    background-color: white;

    &:hover {
      cursor: pointer;
    }
  }
}

.hidden {
  display: none;
}

.flex-row-center {
  display: flex;
  justify-content: center;
  align-items: center;
}